import beamLogo from 'v1/assets/icons/home-page/hero/beam.svg'
import mindbodyLogo from 'v1/assets/icons/home-page/hero/mindbody.svg'
import workatoLogo from 'v1/assets/icons/home-page/hero/workato.svg'
import brighterAILogo from 'v1/assets/icons/home-page/hero/brighter_ai.svg'
import carousellLogo from 'v1/assets/icons/home-page/hero/carousell.svg'
// import caveLogo from 'v1/assets/icons/home-page/hero/cave-logo.svg'
import cybusLogo from 'v1/assets/icons/home-page/hero/cybus.svg'
import cycleLogo from 'v1/assets/icons/home-page/hero/cycle.svg'
import multiplierLogo from 'v1/assets/icons/home-page/hero/multiplier.svg'
// import oodjiLogo from 'v1/assets/icons/home-page/hero/oodji-logo.svg'
import OptilyzLogo from 'v1/assets/icons/home-page/hero/optilyz.svg'
import quincusLogo from 'v1/assets/icons/home-page/hero/quincus.svg'
// import saperedLogo from 'v1/assets/icons/home-page/hero/sapered-logo.svg'
import vytalLogo from 'v1/assets/icons/home-page/hero/vytal.svg'

import ZenCoreIcon from 'v1/assets/icons/home-page/Zen CORE.svg'
import ZenHrIcon from 'v1/assets/icons/home-page/Zen HR.svg'
import ZenItIcon from 'v1/assets/icons/home-page/Zen IT.svg'
import ZenExIcon from 'v1/assets/icons/home-page/Zen EX.svg'

import userIcon from 'v1/assets/icons/home-page/all-in-one-solution/user.svg'
import clockEditIcon from 'v1/assets/icons/home-page/all-in-one-solution/clock-edit.svg'
import rocketIcon from 'v1/assets/icons/home-page/all-in-one-solution/rocket.svg'
import coinPlusIcon from 'v1/assets/icons/home-page/all-in-one-solution/coin-plus.svg'
import moneySettingsIcon from 'v1/assets/icons/home-page/all-in-one-solution/money-settings.svg'
import smartphoneChargingIcon from 'v1/assets/icons/home-page/all-in-one-solution/smartphone-charging.svg'
import navIcon from 'v1/assets/icons/home-page/all-in-one-solution/nav-icons.svg'
import appsIcon from 'v1/assets/icons/home-page/all-in-one-solution/apps.svg'
import sheildLockIcon from 'v1/assets/icons/home-page/all-in-one-solution/shield-lock.svg'
import chatIcon from 'v1/assets/icons/home-page/all-in-one-solution/chats.svg'
import userNewIcon from 'v1/assets/icons/home-page/all-in-one-solution/user-new.svg'
import monitorSearchIcon from 'v1/assets/icons/home-page/all-in-one-solution/monitor-search.svg'
import chatTextIcon from 'v1/assets/icons/home-page/all-in-one-solution/chat-text.svg'
import userSearchIcon from 'v1/assets/icons/home-page/all-in-one-solution/user-search.svg'
import folderFolderIcon from 'v1/assets/icons/home-page/all-in-one-solution/folder-folder.svg'
import flow3Icon from 'v1/assets/icons/home-page/all-in-one-solution/flow-3.svg'
import analyticsIcon from 'v1/assets/icons/home-page/all-in-one-solution/analytics.svg'
import documentAwardIcon from 'v1/assets/icons/home-page/all-in-one-solution/document-award.svg'

import ZenCoreED from 'v1/assets/icons/home-page/zen-core-ed-image.webp'
import ZenCoreDM from 'v1/assets/icons/home-page/zen-core-dm-image.webp'
import ZenCoreWA from 'v1/assets/icons/home-page/zen-core-wa-image.webp'
import ZenCoreRA from 'v1/assets/icons/home-page/zen-core-ra-image.webp'
import ZenCorePP from 'v1/assets/icons/home-page/zen-core-pp-image.webp'

import ZenHrHRIS from 'v1/assets/icons/home-page/zen-hr-chr-image.webp'
import ZenHrEP from 'v1/assets/icons/home-page/zen-hr-ep-image.webp'
import ZenHrPms from 'v1/assets/icons/home-page/zen-hr-pms-image.webp'
import ZenHrTA from 'v1/assets/icons/home-page/zen-hr-ta-image.webp'
import ZenHrHrc from 'v1/assets/icons/home-page/zen-hr-hrc-image.webp'

import ZenItDM from 'v1/assets/icons/home-page/zen-it-dm-image.webp'
import ZenItImgp from 'v1/assets/icons/home-page/zen-it-imgp-image.webp'
import ZenItAsm from 'v1/assets/icons/home-page/zen-it-asm-image.webp'
import ZenItIam from 'v1/assets/icons/home-page/zen-it-iam-image.webp'
import ZenItIth from 'v1/assets/icons/home-page/zen-it-ith-image.webp'

import ZenExAiHr from 'v1/assets/icons/home-page/zen-ex-ai-hr-image.webp'
import ZenExAiIt from 'v1/assets/icons/home-page/zen-ex-ai-it-image.webp'
import ZenExZenAi from 'v1/assets/icons/home-page/zen-ex-zen-ai-image.webp'

import ZenPayrollPP from 'v1/assets/icons/home-page/zen-payroll-pp-image.webp'
import ZenPayrollUsp from 'v1/assets/icons/home-page/zen-payroll-usp-image.webp'
import ZenPayrollGcm from 'v1/assets/icons/home-page/zen-payroll-gcm-image.webp'
import ZenPayrollGp from 'v1/assets/icons/home-page/zen-payroll-gp-image.webp'
import ZenPayrollEor from 'v1/assets/icons/home-page/zen-payroll-eor-image.webp'

import boxImg from 'v1/assets/icons/home-page/global-it/box.svg'
import browserImg from 'v1/assets/icons/home-page/global-it/browser-side-bar.svg'
import creditImg from 'v1/assets/icons/home-page/global-it/credit-card-link.svg'
import flowImg from 'v1/assets/icons/home-page/global-it/flow-3.svg'
import laptopImg from 'v1/assets/icons/home-page/global-it/laptop-cloud.svg'
import phoneImg from 'v1/assets/icons/home-page/global-it/phone-message.svg'
import shieldImg from 'v1/assets/icons/home-page/global-it/shield-lock-2.svg'
import userImg from 'v1/assets/icons/home-page/global-it/user-flash.svg'

import zenAdminOffersImg from 'v1/assets/icons/home-page/zen-admin-offers/zen-admin-offers.webp'
import zenAdminOffersMobImg from 'v1/assets/icons/home-page/zen-admin-offers/zenadmin-offers-m1.webp'
import appsImg from 'v1/assets/icons/home-page/zen-admin-offers/apps.svg'
import chatImg from 'v1/assets/icons/home-page/zen-admin-offers/chat.svg'
import iphoneImg from 'v1/assets/icons/home-page/zen-admin-offers/iphone.svg'
import laptopsettingImg from 'v1/assets/icons/home-page/zen-admin-offers/laptopsetting.svg'
import relaodImg from 'v1/assets/icons/home-page/zen-admin-offers/reload.svg'
import shieldlockImg from 'v1/assets/icons/home-page/zen-admin-offers/shieldlock.svg'
import usersearchImg from 'v1/assets/icons/home-page/zen-admin-offers/usersearch.svg'
import warehouseImg from 'v1/assets/icons/home-page/zen-admin-offers/warehouse.svg'

import procurementImg from 'v1/assets/icons/home-page/inv-management/procurement.webp'
import accessImg from 'v1/assets/icons/home-page/inv-management/access.webp'
import itImg from 'v1/assets/icons/home-page/inv-management/it.webp'
import mobileImg from 'v1/assets/icons/home-page/inv-management/mobile.webp'
import saasImg from 'v1/assets/icons/home-page/inv-management/saas.webp'

import {
  ZEN_IT_HOME,
  MOBILE_DEVICE_MANAGEMENT,
  INVENTORY_MANAGEMENT,
  SAAS_MANAGEMENT,
  IDENTITY_MANAGEMENT,
  IT_HELPDESK,
  WORKFLOW_AUTOMATION,
  DOCUMENT_MANGEMENT,
  EMPLOYEE_DIRECTORY,
  POLICIES_AND_PERMISSIONS,
  REPORTS_AND_ANALYTICS,
  TIME_AND_ATTENDANCE,
  PERFORMANCE_MANAGEMENT,
  ZEN_HR,
  ZEN_EX,
} from 'v1/utils/getters'
import {title} from 'process'

export const heroData = {
  heading: 'Your all-in-one IT platform for global teams',
  subHeading:
    'From startups to Enterprise, ZenAdmin helps you Manage your people, devices, and apps in one place. From procurement to IT support and device retrieval, we automate everything so you can scale efficiently and securely worldwide.',
}

export const heroMarqueeImages = [
  brighterAILogo,
  cycleLogo,
  OptilyzLogo,
  // oodjiLogo,
  // caveLogo,
  beamLogo,
  mindbodyLogo,
  workatoLogo,
  multiplierLogo,
  quincusLogo,
  carousellLogo,
  cybusLogo,
  // saperedLogo,
  vytalLogo,
]

export const allInOneSolutionData = {
  title: 'Your all-in-one IT solution powered by AI',
  multiProductsCardList: [
    {
      key: 'zenhr',
      title: 'Zen HR',
      icon: ZenHrIcon,
      subTitle: 'Empower your HR team to focus on what matters.',
      list: [
        {text: 'Core HR', link: ZEN_HR, icon: userIcon},
        {text: 'Time & Attendance', link: TIME_AND_ATTENDANCE, icon: clockEditIcon},
        {text: 'Performance Management & Survey', link: PERFORMANCE_MANAGEMENT, icon: rocketIcon},
        {text: 'Expense Management', link: '', icon: coinPlusIcon},
        {text: 'Payroll', link: '', icon: moneySettingsIcon},
      ],
      footerData: {
        title: 'Empower your HR team',
        knowMoreLink: '',
      },
    },
    {
      key: 'zenit',
      title: 'Zen IT',
      icon: ZenItIcon,
      subTitle: 'Secure and streamline your IT operations.',
      list: [
        {
          text: 'Mobile Device Management',
          link: MOBILE_DEVICE_MANAGEMENT,
          icon: smartphoneChargingIcon,
        },
        {text: 'Inventory Management', link: INVENTORY_MANAGEMENT, icon: navIcon},
        {text: 'App & SaaS Management', link: SAAS_MANAGEMENT, icon: appsIcon},
        {text: 'Identity Management', link: IDENTITY_MANAGEMENT, icon: sheildLockIcon},
        {text: 'IT Helpdesk', link: IT_HELPDESK, icon: chatIcon},
      ],
      footerData: {
        title: 'Empower your IT team',
        knowMoreLink: ZEN_IT_HOME,
      },
    },
    {
      key: 'zenex',
      title: 'Zen EX',
      icon: ZenExIcon,
      subTitle:
        'Need to find or update something? Just ask our Slack bot for fast, accurate answers — saving you hours of manual work.',
      list: [
        {text: 'AI\u00A0\u00A0HR companion on Slack and Teams', link: '', icon: userNewIcon},
        {text: 'AI\u00A0\u00A0IT companion on Slack and Teams', link: '', icon: monitorSearchIcon},
        {text: 'AI\u00A0\u00A0Knowledge Management', link: '', icon: chatTextIcon},
      ],
      footerData: {
        title: 'Need to find or update something?',
        content: 'Just ask our Slack bot. It’s like your company’s first head of HR/IT',
        knowMoreLink: '',
      },
    },
  ],
  singleProductCardData: {
    title: 'Zen Core',
    icon: ZenCoreIcon,
    subTitle: 'AI powered Automation for HR & IT',
    list: [
      {text: 'Employee Directory', link: EMPLOYEE_DIRECTORY, icon: userSearchIcon},
      {text: 'Document Management', link: DOCUMENT_MANGEMENT, icon: folderFolderIcon},
      {text: 'Workflow Automation', link: WORKFLOW_AUTOMATION, icon: flow3Icon},
      {text: 'Reports & Analytics', link: REPORTS_AND_ANALYTICS, icon: analyticsIcon},
      {text: 'Policies & Permissions', link: POLICIES_AND_PERMISSIONS, icon: documentAwardIcon},
    ],
    footerData: {
      title: 'Zen Ex',
      link: ZEN_EX,
    },
  },
}

export const zenCoreData = {
  topTitle: 'AI-powered Automation for HR & IT',
  title: 'Zen Core',
  titleLogo: ZenCoreIcon,
  desc: 'Experience the platform that saves your time, boosts efficiency, and reduces manual tasks.',
  slides: [
    {
      btnDetails: {
        title: 'Employee Directory',
        boldDesc: 'Store and manage all your people’s data securely in one unified platform.',
        desc: 'Seamlessly collect, organize, and harness data sets to supercharge your workflow, ensuring compliance and saving you hours – say goodbye to inefficient spreadsheets!',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCoreED,
          height: 640,
          width: 840,
        },
      },
    },
    {
      btnDetails: {
        title: 'Document Management',
        boldDesc: 'Manage Documents with Ease',
        desc: `Easily manage your documents with ZenAdmin. Store them directly or link to your preferred tools and e-sign them – all while ensuring full compliance and control.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCoreDM,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Workflow Automation',
        boldDesc: 'Optimize and Automate Your People Processes',
        desc: 'Master your people processes with ease. Create, automate, and collaborate on workflows like onboarding, offboarding, app provisioning, compensation changes, training, and more. Use ZenAdmin templates or start from scratch – save time, reduce errors, and streamline your operations effortlessly.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCoreWA,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Reports & Analytics',
        boldDesc: 'Instant Data Export with ZenAdmin',
        desc: 'Export your people data to a spreadsheet with just two clicks. The fastest way to analyze your data using your preferred tools and make data-driven decisions effortlessly.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCoreRA,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Policies and Permissions',
        boldDesc: 'Securely Automate and Control Employee Access',
        desc: 'Automate and control what employees can see and do with robust security. Enforce your company policies automatically to ensure seamless compliance, prevent unauthorized access, and save time.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenCorePP,
          height: 640,
          width: 836,
        },
      },
    },
  ],
}

export const zenHRData = {
  topTitle: 'AI powered HR Management',
  title: 'Zen HR',
  titleLogo: ZenHrIcon,
  desc: 'The HRIS platform designed with a global-first approach',
  slides: [
    {
      btnDetails: {
        title: 'Core HR',
        boldDesc: 'Achieve Operational Excellence with Zen HRIS',
        desc: 'Streamline operations, elevate the employee experience, and reduce costs with ZenAdmin HRIS — a system loved by admins, executives, HR, and employees. Experience seamless automation and integration designed for global teams.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrHRIS,
          height: 640,
          width: 840,
        },
      },
    },
    {
      btnDetails: {
        title: 'Time & Attendance',
        boldDesc: 'Effortlessly Schedule Shifts and Track Working Hours',
        desc: 'Effortless time tracking that saves you time and reduces manual tasks. Boost productivity and minimize payroll errors, while seamlessly integrating with your payroll system.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrTA,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'HR Concierge',
        boldDesc: 'Global HR Concierge – Admin services tailored for you',
        desc: `Eliminate tedious compliance tasks with ZenAdmin's HR Concierge technology. Our services automate time-intensive compliance processes and give you access to HR experts, ensuring you stay compliant and focused on growing your business without the administrative burden.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrHrc,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Employee Portal',
        boldDesc: 'A Workspace for Your Team',
        desc: `Empower your team with access to check time off balances, view payslips, update personal information, and more.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrEP,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Performance Management & Survey',
        boldDesc: 'Effortlessly Build High-Performing Teams',
        desc: `Performance management powered by real-time employee data. Easily set up and maintain performance processes throughout the employee lifecycle, helping you make data-driven decisions and improve team outcomes.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenHrPms,
          height: 640,
          width: 836,
        },
      },
    },
  ],
}

export const zenITData = {
  topTitle: 'AI Powered IT Management',
  title: 'Zen IT',
  titleLogo: ZenItIcon,
  desc: 'Strengthen security and automate device management',
  slides: [
    {
      btnDetails: {
        title: 'Mobile Device Management',
        boldDesc: 'One Solution to Secure Your Devices',
        desc: 'Strengthen security and streamline device management with MDM, providing real-time access to user and device data. Ensure full control over devices, reduce risks, and improve operational efficiency.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItDM,
          height: 640,
          width: 840,
        },
      },
    },
    {
      btnDetails: {
        title: 'Inventory Management & Global Procurement',
        boldDesc: 'Global Logistics, Storage, Procurement, and More',
        desc: `ZenAdmin takes care of the heavy lifting of fleet management, from repair to storage to checking your machines. With one click, we handle the rest — reducing downtime, ensuring operational efficiency, and managing your inventory globally.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItImgp,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'App & SaaS Management',
        boldDesc: 'One Platform to Manage All SaaS and Access',
        desc: 'Transform from SaaS chaos to control. ZenAdmin combines HRIS and IdP into one platform, offering enhanced security with granular access controls for the entire user lifecycle. Discover and manage every app, optimize costs, and seamlessly unify SaaS contracts — reducing risks and simplifying compliance.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItAsm,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Identity & Access Management',
        boldDesc: 'Unified Access Management for Secure, Modern Enterprises',
        desc: 'One platform that consolidates access control throughout the entire user lifecycle, covering federated, unfederated, and shadow apps. Providing a comprehensive solution with enhanced security, compliance, and seamless user management for modern enterprises.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItIam,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'IT Helpdesk',
        boldDesc: '24/7 Seamless Global IT Helpdesk Support',
        desc: `Choosing the right IT support is critical. ZenAdmin's IT Global Helpdesk offers reliable, efficient solutions, with 24/7 tailored support to meet your needs. Experience world-class IT support that reduces downtime, streamlines your operations, and enhances productivity — all at a fraction of the cost.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenItIth,
          height: 640,
          width: 836,
        },
      },
    },
  ],
}

export const zenEXData = {
  topTitle: 'AI powered Employee Experience',
  title: 'Zen EX',
  titleLogo: ZenExIcon,
  desc: 'Need to find or update something? Just ask our Slack bot — your AI-powered assistant for HR/IT. Save time, reduce manual tasks, and get the information you need instantly.',
  slides: [
    // {
    //   btnDetails: {
    //     title: 'AI HR Companion on Slack and Teams:',
    //     boldDesc: 'Seamless Information Access in Slack & Teams powered by AI',
    //     desc: 'Find any HR information across your work apps without leaving Slack. When you ask a question, ZenAdmin’s AI bot analyzes all your connected work apps to deliver the right answer instantly — from leave balances to policy updates.',
    //     linkLabel: 'Learn more',
    //     link: '',
    //     linkIcon: '',
    //   },
    //   slideDetails: {
    //     img: {
    //       src: ZenExAiHr,
    //       height: 640,
    //       width: 840,
    //     },
    //   },
    // },
    {
      btnDetails: {
        title: 'AI IT Companion on Slack and Teams',
        boldDesc: 'Seamless Information Access in Slack & Teams powered by AI',
        desc: `Find any IT information across your work apps without leaving Slack or Teams. Whether it's troubleshooting, software requests, or IT tickets, ZenAdmin’s AI bot analyzes all your connected apps to deliver the right answer instantly.`,
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenExAiIt,
          height: 640,
          width: 836,
        },
      },
    },
    {
      btnDetails: {
        title: 'Zen AI',
        boldDesc: 'Maximize Efficiency and Focus with ZenAdmin AI',
        desc: 'Boost efficiency, automate routine tasks, and make data-driven decisions with ZenAdmin AI — empowering your team to focus on what truly matters.',
        linkLabel: 'Learn more',
        link: '',
        linkIcon: '',
      },
      slideDetails: {
        img: {
          src: ZenExZenAi,
          height: 640,
          width: 836,
        },
      },
    },
  ],
}

export const zenPayrollData = {
  headingData: {
    topTitle: 'Powerful global all-in-one solution',
    title: 'Zen Payroll',
    subTitleText1: `No need for 10 different providers and tools, ZenAdmin finally solves it all.`,
    subTitleText2: ' Pay employees and contractors your way — faster and with fewer complications.',
  },
  tabs: [
    {
      key: 'preliminaryPayroll',
      title: 'Preliminary Payroll',
      subTitle: 'Instant Access to Your Payroll Data',
      paragraph:
        'Whether you work with an accountancy firm or a payroll provider, ZenAdmin grants you instant access to the data you need. Effortlessly access and manage your payroll information in real time, whenever you need it.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollPP,
    },
    {
      key: 'uSPayroll',
      title: 'US Payroll',
      subTitle: 'Payroll So Powerful It Can Run Itself',
      paragraph:
        'ZenAdmin’s automated payroll system is so powerful, it practically runs itself. Simplify your payroll processes, reduce errors, and experience effortless management — saving you time and resources.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollUsp,
    },
    {
      key: 'globalPayroll',
      title: 'Global Payroll',
      subTitle: 'Fast and Accurate Global Payroll for Peace of Mind',
      paragraph:
        'ZenAdmin offers an all-in-one payroll solution. Experience fast, accurate global payroll services, ensuring compliance and delivering peace of mind for international entities.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollGp,
    },
    {
      key: 'globalCM',
      title: 'Global Contractor Management',
      subTitle: 'Seamlessly Pay Contractors Worldwide',
      paragraph:
        'Hire and pay contractors anywhere in the world with ease. Elevate the contractor experience, ensure timely payments, and streamline compliance with effortless automation.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollGcm,
    },
    {
      key: 'employerOfRecord',
      title: 'Employer of Record',
      subTitle: 'Effortlessly Hire and Pay Global Employees',
      paragraph:
        'Get started at lightning speed. ZenAdmin handles the complexities of international EOR entities and compliance, seamlessly integrating your global workforce into one unified HR system — ensuring smooth operations across borders.',
      showLearnMore: true,
      learnMoreLink: '',
      image: ZenPayrollEor,
    },
  ],
}

export const globalITData = {
  title: 'Features that will make Global IT easy',
  cards: [
    {
      desc: 'Easy on/off boarding with Workflow ',
      img: userImg,
    },
    {
      desc: 'Connect your HR system',
      img: creditImg,
    },
    {
      desc: 'Procure Hardware and Software Globally',
      img: boxImg,
    },
    {
      desc: 'Assign, track,store, repair & resell',
      img: laptopImg,
    },
    {
      desc: '24/7 IT Support',
      img: phoneImg,
    },
    {
      desc: 'SaaS Management',
      img: browserImg,
    },
    {
      desc: 'Access & Identity Management',
      img: shieldImg,
    },
    {
      desc: 'IT Automation',
      img: flowImg,
    },
  ],
}

export const zenAdminOffersData = {
  title: 'What ZenAdmin Offers?',
  img: zenAdminOffersImg,
  mobImage: zenAdminOffersMobImg,
  product: [
    {
      desc: 'Hardware & Software Purchasing',
      img: warehouseImg,
    },
    {
      desc: 'Mobile Device Management',
      img: iphoneImg,
    },
    {
      desc: 'Access & Identity Management',
      img: shieldlockImg,
    },
    {
      desc: 'SaaS Management',
      img: appsImg,
    },
  ],
  services: [
    {
      desc: 'Onboarding & Offboarding',
      img: usersearchImg,
    },
    {
      desc: 'Device Lifecycle & Compliance',
      img: laptopsettingImg,
    },
    {
      desc: 'Renewal & Refurbishing',
      img: relaodImg,
    },
    {
      desc: 'IT Support 24/7',
      img: chatImg,
    },
  ],
}

export const invManagementData = [
  {
    btnText: 'Procurement & Inventory Management',
    title: 'Procurement & Inventory Management',
    subTitle:
      'Buy all the equipment your employee need — new or existing. Centralize invoices, tracking logistic and store worldwide.',
    img: procurementImg,
  },
  {
    btnText: 'Mobile Device Management',
    title: 'One Solution to Secure You Devices',
    subTitle:
      'Strengthen security and streamline device management with MDM that provides real-time access to native user and device data.',
    img: mobileImg,
  },
  {
    btnText: 'SaaS Management',
    title: 'One SaaS to Manage All SaaS and Access',
    subTitle:
      'Transform from SaaS chaos to control. ZenAdmin combines an HRIS and IdP into one platform, providing enhanced security with granular access controls across the user lifecycle. Discover and manage every app, optimize costs, and unify SaaS contracts seamlessly.',
    img: saasImg,
  },
  {
    btnText: 'Access Management',
    title: 'One Platform, Total Access Control',
    subTitle:
      'ZenAdmin unifies identity and access management across all your apps and systems. Our platform automates user provisioning and de-provisioning to give you complete control over access points within your organization.',
    img: accessImg,
  },
  {
    btnText: 'IT Support',
    title: 'Seamless IT Helpdesk Support',
    subTitle: `Choosing the right IT support is critical. ZenAdmin's IT Global Helpdesk offers reliable, efficient solutions, 24/7 tailored to your needs. Experience top-notch IT support that streamlines your operations at a fraction of the cost.`,
    img: itImg,
  },
]
